.updated-games {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.updated-games a {
 min-width: 0;
 background-color: var(--backgroundCard);
}

.updated-game {
  display: flex;
  flex-direction: column;
}

.updated-game img {
  width: 100%;
}



.updated-game-info {
  padding: 0 .25rem .25rem .25rem;
}




.updated-game-name {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.updated-game-category {
  margin-top: .25rem;
}

.updated-game-category span {
  background-color: var(--backgroundColor);
  border-radius: 5px;
  padding: .25rem .5rem;
  margin-right: .5rem;
  font-size: .75rem;
}

.updated-game-price {
  margin-top: .5rem;
  background-color: var(--backgroundColor);
  display: inline-block;
  padding-right: .5rem;
}

.updated-game-nodiscount {
  padding-left: .5rem;
}

.updated-discount-percent {
  display: inline-block;
  height: 100%;
  background-color: green;
}

.updated-original-price {
  margin: 0 .25rem;
  text-decoration: line-through;
  font-size: .75rem;
}

.updated-game-category {
  display: flex;
  flex-wrap: wrap;
}

.updated-game-category span {
  margin-bottom: .25rem;
}

@media screen and (min-width: 768px) {
  .updated-game {
    display: flex;
    flex-direction: row;
  }

  .updated-game img {
    flex: 1 1 50%;
  }

  .updated-game-info {
    min-width: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1024px) {
  .updated-games {
    grid-template-columns: 1fr 1fr;
    align-items: stretch;
  }

  .updated-game {
    flex-direction: column;
  }

  .updated-game-category {
    flex-direction: row;
  }
}