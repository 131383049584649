.form-section {
  color: white;
  max-width: 300px;
  margin: 0 auto;
  height: 86vh;
  margin-top: 3rem;
}

.form-section h1 {
  text-align: center;
  margin-bottom: 1rem;
}

.form-section label {
  display: block;
  margin-bottom: 1rem;
}

.form-section span {
  display: block;
  margin-bottom: .5rem;
}

.form-section input {
  width: 100%;
  padding: .5em .25em;
  font-size: 1rem;
}

.form-section button {
  display: block;
  background: linear-gradient(90deg, #06BFFF 0%, #2D73FF 100%);
  width: 60%;
  margin: 0 auto;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  padding: .5em 0;
}

.form-section a {
  margin-top: 1rem;
  display: block;
  text-align: center;
}

.test {
  margin-top: 3rem;
  text-align: center;
  line-height: 1.5;
}