
nav {
  display: none;
}

.mobile-container {
  width: 100%;
  background-color: var(--navDark);
  position: relative;
}

.mobile-topbar {
  max-width: 90%;
  margin: 0 auto;
  height: 8vh;
  display: flex;
  align-items: center;
  box-shadow: 0 0 7px 0 rgb(0 0 0 / 75%);
}


.mobile-topbar img {
  width: 2rem;
}

.mobile-topbar .mobile-logo {
  margin: 0 auto;
}

.mobile-topbar .mobile-logo img {
  width: 3rem;
}


.mobile-nav {
  height: 6vh;
  background-color:var(--navBlue);
  color: white;
}

.mobile-nav ul{
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.mobile-nav ul li {
  height: 100%;
}

.mobile-nav ul li a {
  height: 100%;
  display: block;
  display: flex;
  align-items: center;
}

/* sidebar */
.mobile-sidebar-container {
  display: flex;
  position: absolute;
  height: 86vh;
  transition: all ease-in-out 0.2s;
  z-index: 2;
  width: 100%;
  background:rgb(0 0 0 / 75%);
}

.mobile-sidebar {
  background-color: var(--navDark);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  min-width: 195px;
  max-width: 25%;
  padding-top: 1rem;
}

.mobile-sidebar-close {
  color: white;
  font-size: 1.5rem;
  padding-left: .5em;
  width: 40%;
}

.mobile-sidebar-disable {
  transform: translateX(-100%);
  transition: all ease-in-out 0.2s;
}

.show-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mobile-sidebar-user {
  padding: 0.5rem 0;
  width: 100%;
  border-bottom: 1px solid var(--textColor);
}

.mobile-sidebar img {
  width: 2rem;
}

.mobile-sidebar-login {
  padding: 0.5rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.mobile-sidebar-login a{
  width: 50%;
}

.mobile-sidebar-logout {
  width: 100%;
  padding: 0.5rem 0;
}

.mobile-sidebar-bag {
  padding: 0.5rem 0;
  width: 100%;
  border-bottom: 1px solid var(--textColor);
}

.mobile-badge {
  position: relative;
}

.mobile-badge span {
  color: white;
  font-size: 0.75rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: tomato;
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.5rem;
  top: -6px;
  right: -6px;
}

@media screen and (min-width: 1025px) {
  .mobile-container {
    display: none;
  }

  .mobile-sidebar-container {
    display: none;
  }

  nav {
    display: block;
    
  }

  .desktop-nav1 {
    height: 8vh;
    background-color: var(--navDark);
    position: relative;
  }

  .desktop-container {
    max-width: 80%;
    margin: 0 auto;
    height: 100%;
  }

  .desktop-topbar img {
    width: 4rem;
  }

  .desktop-topbar {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .desktop-topbar a {
    display: flex;
    align-items: center;
  }

  .desktop-search {
    position: absolute;
    left: 0;
    max-width: 20%;
  }

  .desktop-account {
    color: white;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
  }

  .desktop-username {
    padding: 1rem;
  }
  
  .desktop-account a {
    padding: 1rem;
  }

  .desktop-account-forhover {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }

  .desktop-account-hover {
    position: absolute;
    top: 100%;
    left: -50%;
    width: 200%;
    background-color: var(--navDark);
    display: none;
  }

  .desktop-logout {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-top: 1px solid white;
    cursor: pointer;
  }

  .desktop-account-forhover:hover .desktop-account-hover {
    display: block;
    z-index: 2;
  }

  .desktop-account-hover a:hover {
    background-color: var(--navHover);
  }

  .desktop-logout:hover {
    background-color: var(--navHover);
  }

  .desktop-account img {
    width: 2rem;
  }

  .desktop-nav2{
    height: 6vh;
    background-color: var(--navBlue);
  }

  .desktop-nav2 ul {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .desktop-nav2 ul li {
    height: 100%;
  }

  .desktop-nav2 li a {
    color: white;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    font-size: 1.25rem;
  }

  .desktop-badge {
    position: relative;
  }

  .desktop-badge span {
    background-color: tomato;
    position: absolute;
    border-radius: 50%;
    width: 1.25rem;
    height: 1.25rem;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 13px;
    right: 10px;
    font-size: 0.75rem;
  }
}

@media (hover: none) {
  .mobile-dropdown {
    display: none;
  }

}


@media (hover: hover) {
  .desktop-nav2 li a:hover {
    background-color: var(--backgroundColor);
  }

  .hover-dropdown:hover .dropdown {
    display: flex;
  }
}

