.home-desktop {
  display: none;
}

.home-new-release-img {
  position: relative;
}

.home-new-release-img img {
  width: 100%;

}

.home-new-release-img button {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  padding: 0.5rem;
  border-radius: 4px;
  border: none;
  background-color: var(--backgroundColor);
  color: white;
}

@media screen and (min-width: 1025px) {
  
  .home-mobile {
    display: none;
  }

  .home-desktop {
    display: flex;
    background-color: var(--navDark);
  }

  .home-desktop p {
    font-size: 0.75rem;
  }

  .main-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
  }

  .main-img img {
    width: 100%;
  }

  .main-info {
    padding: 0.5rem;
    margin-left: 1rem;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .new-imgs {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .new-imgs-two {
    display: flex;
  }

  .new-imgs-two img {
    max-width: 100%;
  }

  .new-imgs-one {
    max-width: 50%;
  }

  .new-imgs-one img {
    max-width: 99%;
  }
  
  .right-img {
    margin-left: 1%;
  }

  .desktop-discount {
    background-color: green;
    padding: 0.25rem;
  }

  .original-price {
    text-decoration: line-through;
    margin: 0 0.25rem;
  }
}