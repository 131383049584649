.dropdown {
  position: absolute;
  background-color: var(--backgroundDropdown);
  left: 0;
  width: 100%;
  display: none;
  justify-content: center;
  padding-top: 1rem;
  z-index: 2;
}

.dropdown-section {
  margin: 0 1.5rem;
}

.dropdown .dropdown-section ul {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.dropdown .dropdown-section a {
  height: auto;
  padding: 0.25rem 0;
  font-size: 1rem;
}

.tag {
  font-weight: bold;
}

.dropdown .dropdown-section a:hover {
  border-bottom: 1px solid var(--textColor);
  background-color: var(--backgroundDropdown);
}

@media (hover:none) {
  .dropdown {
    display: flex;
  }
}

@media screen and (max-width: 425px) and (hover:none) {
  .dropdown {
    flex-direction: column;
  }

  .dropdown-section {
    display: flex;
    justify-content: center;
  }

  .dropdown-section div {
    flex:1 1 100%;
  }
}
