.home-container {
  max-width: 96%;
  margin: 1rem auto;
  color: white;
}

.home-container p {
  margin-top: 3rem;
}

.home-container ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.home-container ul li {
  font-weight: 400;
  background: linear-gradient(90deg, var(--linearColorLightBlue) 0%, var(--linearColorDarkBlue) 100%);
  height: 3rem;
}

.home-container ul li a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.game-groups {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

@media screen and (min-width: 768px) {
  .game-groups {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 1024px) {
  .home-container {
    max-width: 80%;
  }

  .home-container ul {
    grid-template-columns: repeat(4, 1fr);
  }

  .home-container ul li a {
    font-size: 1.25rem;
  }

  .game-groups {
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  }
}

@media screen and (min-width: 1440px) {
  .home-container {
    max-width: 60%;
  }
}
