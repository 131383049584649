@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap);
:root {
  --navDark: #171a21;
  --navBlue: #346a9b;
  --textColor: #8A97A0;
  --backgroundColor: #1d2d4b;
  --backgroundDropdown: #2D3E4C;
  --backgroundCard: #2884ac;
  --linearColorLightBlue: #06BFFF;
  --linearColorDarkBlue: #2D73FF;
  --navHover: #343744;
  --cartItemBackgroundColor: #16202d;
}

* {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  position: relative;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: .5rem;
  margin: .5rem 0;
}

/* .container {
  max-width: 90%;
  margin: 0 auto;
} */

.App {
  background-color: var(--backgroundColor);
  min-height: 100vh;
  /* height: 100vh; */
  /* overflow: hidden; */
  /* max-height: 90%; */
}

.disable-scroll {
  height: 100vh;
  overflow: hidden;
}

@media screen and (min-width: 1025px) {
  .disable-scroll {
    height: auto;
    overflow: visible;
  }
}

nav {
  display: none;
}

.mobile-container {
  width: 100%;
  background-color: var(--navDark);
  position: relative;
}

.mobile-topbar {
  max-width: 90%;
  margin: 0 auto;
  height: 8vh;
  display: flex;
  align-items: center;
  box-shadow: 0 0 7px 0 rgb(0 0 0 / 75%);
}


.mobile-topbar img {
  width: 2rem;
}

.mobile-topbar .mobile-logo {
  margin: 0 auto;
}

.mobile-topbar .mobile-logo img {
  width: 3rem;
}


.mobile-nav {
  height: 6vh;
  background-color:var(--navBlue);
  color: white;
}

.mobile-nav ul{
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.mobile-nav ul li {
  height: 100%;
}

.mobile-nav ul li a {
  height: 100%;
  display: block;
  display: flex;
  align-items: center;
}

/* sidebar */
.mobile-sidebar-container {
  display: flex;
  position: absolute;
  height: 86vh;
  transition: all ease-in-out 0.2s;
  z-index: 2;
  width: 100%;
  background:rgb(0 0 0 / 75%);
}

.mobile-sidebar {
  background-color: var(--navDark);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  min-width: 195px;
  max-width: 25%;
  padding-top: 1rem;
}

.mobile-sidebar-close {
  color: white;
  font-size: 1.5rem;
  padding-left: .5em;
  width: 40%;
}

.mobile-sidebar-disable {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: all ease-in-out 0.2s;
}

.show-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mobile-sidebar-user {
  padding: 0.5rem 0;
  width: 100%;
  border-bottom: 1px solid var(--textColor);
}

.mobile-sidebar img {
  width: 2rem;
}

.mobile-sidebar-login {
  padding: 0.5rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.mobile-sidebar-login a{
  width: 50%;
}

.mobile-sidebar-logout {
  width: 100%;
  padding: 0.5rem 0;
}

.mobile-sidebar-bag {
  padding: 0.5rem 0;
  width: 100%;
  border-bottom: 1px solid var(--textColor);
}

.mobile-badge {
  position: relative;
}

.mobile-badge span {
  color: white;
  font-size: 0.75rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: tomato;
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.5rem;
  top: -6px;
  right: -6px;
}

@media screen and (min-width: 1025px) {
  .mobile-container {
    display: none;
  }

  .mobile-sidebar-container {
    display: none;
  }

  nav {
    display: block;
    
  }

  .desktop-nav1 {
    height: 8vh;
    background-color: var(--navDark);
    position: relative;
  }

  .desktop-container {
    max-width: 80%;
    margin: 0 auto;
    height: 100%;
  }

  .desktop-topbar img {
    width: 4rem;
  }

  .desktop-topbar {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .desktop-topbar a {
    display: flex;
    align-items: center;
  }

  .desktop-search {
    position: absolute;
    left: 0;
    max-width: 20%;
  }

  .desktop-account {
    color: white;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
  }

  .desktop-username {
    padding: 1rem;
  }
  
  .desktop-account a {
    padding: 1rem;
  }

  .desktop-account-forhover {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }

  .desktop-account-hover {
    position: absolute;
    top: 100%;
    left: -50%;
    width: 200%;
    background-color: var(--navDark);
    display: none;
  }

  .desktop-logout {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-top: 1px solid white;
    cursor: pointer;
  }

  .desktop-account-forhover:hover .desktop-account-hover {
    display: block;
    z-index: 2;
  }

  .desktop-account-hover a:hover {
    background-color: var(--navHover);
  }

  .desktop-logout:hover {
    background-color: var(--navHover);
  }

  .desktop-account img {
    width: 2rem;
  }

  .desktop-nav2{
    height: 6vh;
    background-color: var(--navBlue);
  }

  .desktop-nav2 ul {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .desktop-nav2 ul li {
    height: 100%;
  }

  .desktop-nav2 li a {
    color: white;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    font-size: 1.25rem;
  }

  .desktop-badge {
    position: relative;
  }

  .desktop-badge span {
    background-color: tomato;
    position: absolute;
    border-radius: 50%;
    width: 1.25rem;
    height: 1.25rem;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 13px;
    right: 10px;
    font-size: 0.75rem;
  }
}

@media (hover: none) {
  .mobile-dropdown {
    display: none;
  }

}


@media (hover: hover) {
  .desktop-nav2 li a:hover {
    background-color: var(--backgroundColor);
  }

  .hover-dropdown:hover .dropdown {
    display: flex;
  }
}


.searchbar {
  display: flex;
  grid-gap: .25rem;
  gap: .25rem;
}

.searchbar input {
  background-color: white;
  width: 100%;
  border-radius: 5px;
  padding: 0.25rem;
}

.searchbar input::-webkit-input-placeholder {
  color: var(--navDark);
}

.searchbar input:-ms-input-placeholder {
  color: var(--navDark);
}

.searchbar input::placeholder {
  color: var(--navDark);
}

.searchbar button {
  background-color: var(--navDark);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  cursor: pointer;
}

.searchbar button img {
  width: 1.5rem;
}
.dropdown {
  position: absolute;
  background-color: var(--backgroundDropdown);
  left: 0;
  width: 100%;
  display: none;
  justify-content: center;
  padding-top: 1rem;
  z-index: 2;
}

.dropdown-section {
  margin: 0 1.5rem;
}

.dropdown .dropdown-section ul {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.dropdown .dropdown-section a {
  height: auto;
  padding: 0.25rem 0;
  font-size: 1rem;
}

.tag {
  font-weight: bold;
}

.dropdown .dropdown-section a:hover {
  border-bottom: 1px solid var(--textColor);
  background-color: var(--backgroundDropdown);
}

@media (hover:none) {
  .dropdown {
    display: flex;
  }
}

@media screen and (max-width: 425px) and (hover:none) {
  .dropdown {
    flex-direction: column;
  }

  .dropdown-section {
    display: flex;
    justify-content: center;
  }

  .dropdown-section div {
    flex:1 1 100%;
  }
}

.home-container {
  max-width: 96%;
  margin: 1rem auto;
  color: white;
}

.home-container p {
  margin-top: 3rem;
}

.home-container ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  gap: 1rem;
}

.home-container ul li {
  font-weight: 400;
  background: linear-gradient(90deg, var(--linearColorLightBlue) 0%, var(--linearColorDarkBlue) 100%);
  height: 3rem;
}

.home-container ul li a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.game-groups {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  gap: 1rem;
}

@media screen and (min-width: 768px) {
  .game-groups {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 1024px) {
  .home-container {
    max-width: 80%;
  }

  .home-container ul {
    grid-template-columns: repeat(4, 1fr);
  }

  .home-container ul li a {
    font-size: 1.25rem;
  }

  .game-groups {
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  }
}

@media screen and (min-width: 1440px) {
  .home-container {
    max-width: 60%;
  }
}

.loading {
  min-height: 86vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}
@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.home-desktop {
  display: none;
}

.home-new-release-img {
  position: relative;
}

.home-new-release-img img {
  width: 100%;

}

.home-new-release-img button {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  padding: 0.5rem;
  border-radius: 4px;
  border: none;
  background-color: var(--backgroundColor);
  color: white;
}

@media screen and (min-width: 1025px) {
  
  .home-mobile {
    display: none;
  }

  .home-desktop {
    display: flex;
    background-color: var(--navDark);
  }

  .home-desktop p {
    font-size: 0.75rem;
  }

  .main-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
  }

  .main-img img {
    width: 100%;
  }

  .main-info {
    padding: 0.5rem;
    margin-left: 1rem;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .new-imgs {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .new-imgs-two {
    display: flex;
  }

  .new-imgs-two img {
    max-width: 100%;
  }

  .new-imgs-one {
    max-width: 50%;
  }

  .new-imgs-one img {
    max-width: 99%;
  }
  
  .right-img {
    margin-left: 1%;
  }

  .desktop-discount {
    background-color: green;
    padding: 0.25rem;
  }

  .original-price {
    text-decoration: line-through;
    margin: 0 0.25rem;
  }
}
.updated-games {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  gap: 1rem;
}

.updated-games a {
 min-width: 0;
 background-color: var(--backgroundCard);
}

.updated-game {
  display: flex;
  flex-direction: column;
}

.updated-game img {
  width: 100%;
}



.updated-game-info {
  padding: 0 .25rem .25rem .25rem;
}




.updated-game-name {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.updated-game-category {
  margin-top: .25rem;
}

.updated-game-category span {
  background-color: var(--backgroundColor);
  border-radius: 5px;
  padding: .25rem .5rem;
  margin-right: .5rem;
  font-size: .75rem;
}

.updated-game-price {
  margin-top: .5rem;
  background-color: var(--backgroundColor);
  display: inline-block;
  padding-right: .5rem;
}

.updated-game-nodiscount {
  padding-left: .5rem;
}

.updated-discount-percent {
  display: inline-block;
  height: 100%;
  background-color: green;
}

.updated-original-price {
  margin: 0 .25rem;
  text-decoration: line-through;
  font-size: .75rem;
}

.updated-game-category {
  display: flex;
  flex-wrap: wrap;
}

.updated-game-category span {
  margin-bottom: .25rem;
}

@media screen and (min-width: 768px) {
  .updated-game {
    display: flex;
    flex-direction: row;
  }

  .updated-game img {
    flex: 1 1 50%;
  }

  .updated-game-info {
    min-width: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1024px) {
  .updated-games {
    grid-template-columns: 1fr 1fr;
    align-items: stretch;
  }

  .updated-game {
    flex-direction: column;
  }

  .updated-game-category {
    flex-direction: row;
  }
}
.game-card {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--backgroundCard);
  min-width: 0;
}

.game-card img {
  width: 100%;
}

.game-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0.25rem;
}

.game-info span {
  display: block;
  font-size: 0.75rem;
  color: white;
}

.game-original-price {
  text-decoration: line-through;
  text-align: right;
}

.game-info-price {
  background-color: var(--backgroundColor);
  padding: 0.25rem;
}

.game-info-discount {
  background-color: green;
}

.game-price-with-discount {
  display: flex;
  align-items: center;
}

.game-name-limit {
  min-width: 0;
  flex-basis: 50%;
}

.game-info-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.footer {
  background-color: var(--navDark);
  color: var(--textColor);
}

.footer-container {
  display: flex;
  justify-content: space-around;
  padding: 1rem;
}

.footer-container img {
  width: 3rem;
}

.footer-container ul li:nth-child(2) {
  margin: .5rem 0;
}

.footer-help p{
  font-weight: bold;
  margin-bottom: 0.5rem;
}

@media screen and (min-width: 768px) {
  .footer-container {
    max-width: 60%;
    margin: 0 auto;
  }
}
.form-section {
  color: white;
  max-width: 300px;
  margin: 0 auto;
  height: 86vh;
  margin-top: 3rem;
}

.form-section h1 {
  text-align: center;
  margin-bottom: 1rem;
}

.form-section label {
  display: block;
  margin-bottom: 1rem;
}

.form-section span {
  display: block;
  margin-bottom: .5rem;
}

.form-section input {
  width: 100%;
  padding: .5em .25em;
  font-size: 1rem;
}

.form-section button {
  display: block;
  background: linear-gradient(90deg, #06BFFF 0%, #2D73FF 100%);
  width: 60%;
  margin: 0 auto;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  padding: .5em 0;
}

.form-section a {
  margin-top: 1rem;
  display: block;
  text-align: center;
}

.test {
  margin-top: 3rem;
  text-align: center;
  line-height: 1.5;
}
.cart-container {
  color: white;
  max-width: 96%;
  margin: 3rem auto 0;
  min-height: 86vh;
}

.cart-container span {
  display: block;
  font-size: .75rem;
  text-align: right;
  text-decoration: line-through;
  color: var(--textColor);
}

.cart-each-game {
  display: flex;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: .25rem;
  background-color: var(--cartItemBackgroundColor);
  padding: .25rem;
}

.cart-each-game p {
  font-size: .875rem;
}

.cart-game-info-container {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.cart-img-container {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  width: 120px;
}

.cart-img-container img {
  width: 100%;
}

.cart-game-price {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cart-game-price p {
  text-align: right;
}

.cart-remove {
  text-decoration: underline;
  cursor: pointer;
  color: var(--textColor);
}

.cart-checkout-container {
  background-color: var(--cartItemBackgroundColor);
  padding: 1.5rem .25rem;
}

.cart-total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.cart-continue-btn {
  display: flex;
  justify-content: flex-end;
}

.cart-continue-btn a, button {
  font-size: 1rem;
  background-color: green;
  border: 1px solid green;
  border-radius: 5px;
  color: white;
  padding: .25em 1em;
  cursor: pointer;
}

.cart-shopping {
  display: inline-block;
  background-color: var(--navBlue);
  padding: .25em 1em;
  border-radius: 5px;
  margin-top: 3rem;
}

.hint {
  color: var(--textColor);
  margin-top: 10rem;
}

@media screen and (min-width: 768px) {
  .cart-each-game {
    padding: .5rem 1rem;
  }

  .cart-checkout-container {
    padding: 1.5rem 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .cart-container {
    max-width: 80%;
  }
}

@media screen and (min-width: 1440px) {
  .cart-container {
    max-width: 60%;
  }
}

@media screen and (min-width: 1900px) {
  .cart-container {
    max-width: 40%;
  }
}
.game-detail {
  position: relative;
  color: white;
}

.game-detail video {
  max-width: 100%;
}

.game-detail-info {
  max-width: 96%;
  margin: 0 auto ;
}

.top-title {
  margin: 1rem 0;
}

.game-detail-info img {
  width: 100%;
}

.game-detail-imgs {
  display: flex;
  flex-direction: column;
  background-color: var(--navDark);
  height: 100%;
  justify-content: space-between;
}

.game-detail-small-img {
  display: flex;
  margin-top: 1rem;
  grid-gap: .5rem;
  gap: .5rem;
}

.game-detail-small-img div{
  flex: 1 1;
  cursor: pointer;
}

.game-detail-video {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.game-detail-video img {
  position: absolute;
  width: 30%;
}

.game-detail-tags-price {
  margin-top: 2rem;
  background-color: var(--navDark);
}

.game-detail-tags-price h2{
  display: none;
}

.game-detail-tag-color {
  color: var(--textColor);
}

.game-detail-tags-price p {
  margin-top: 1rem;
}

.game-detail-short-description {
  display: none;
}

.tags-wrap {
  display: flex;
  flex-wrap: wrap;
}

.tags-wrap a{
  padding: .25rem .5rem;
  background-color: var(--backgroundCard);
  border-radius: 4px;
  margin: 0 .5rem .5rem 0;
  font-size: .75rem;
}


.game-detail-offer-ends {
  color: var(--backgroundCard);
  font-size: .8rem;
}

.game-detail-price-bg-color {
  background-color: #2c3943;
  border-radius: 5px;
  padding: 0 .5rem .5rem .5rem;
}

.align-price-right {
  display: flex;
  justify-content: flex-end;
}

.game-detail-price {
  background-color: var(--navDark);
  display: inline-flex;
  align-items: center;
  padding: .25rem;
  margin-top: 1rem;
  text-align: right;
}

.game-price-nodiscount {
  padding: 0 .25rem;
}

.game-detail-discount-percent {
  background-color: green;
  padding: .5rem;
}

.game-detail-discount {
  margin: 0 .5rem;
  display: inline-block;
}

.game-detail-orignal-price {
  color: var(--textColor);
  text-decoration: line-through;
  font-size: .75rem;
}

.game-detail-btn {
  background-color: green;
  color: white;
  padding: .5rem;
  border: 1px solid green;
  border-radius: 5px;
  cursor: pointer;
}


.game-description-requirments {
  max-width: 96%;
  margin: 3rem auto 3rem;
  background-color: var(--navDark);
  color: var(--textColor);
}

.game-detail-section-h3 {
  background-color: var(--backgroundColor);
  color: white;
  padding-bottom: 1rem;
}

.game-description {
  white-space: pre-line;
  padding: 0.5rem;
}

.text-limit {
  max-height: 45vh;
  overflow: hidden;
}

.read-more {
  color: var(--backgroundCard);
  text-align: center;
}

.requirments {
  padding: 0.5rem;
}

.requirments p{
  margin-bottom: .5rem;
}

.requirments p:nth-child(7){
  margin-bottom: 3rem;
}

@media screen and (min-width: 1024px) {
  .game-detail {
    margin-top: 3rem;
  }

  .game-detail-info {
    background-color: var(--navDark);
    max-width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: flex-end;
  }

  .top-title {
    display: none;
  }

  .game-detail-tags-price {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .game-detail-tags-price h3 {
    padding-left: .5rem;
  }

  .game-detail-tags {
    padding-left: .5rem;
  }

  .game-description-requirments {
    max-width: 80%;
  }

  .requirments {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .requirments p:nth-child(7){
    margin-bottom: .5rem;
  }

  .game-detail-price-bg-color {
    border-radius: 0;
  }
}

@media screen and (min-width: 1440px) {
  .game-detail-info {
    max-width: 60%;
  }

  .game-description-requirments {
    max-width: 60%;
  }

  .game-detail-tags-price h3 {
    display: none;
  }

  .game-detail-tags-price h2 {
    display: block;
    padding-left: .5rem;
  }
}
.games-category {
  color: white;
  max-width: 96%;
  margin: 3rem auto;
}

.games-category-games {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
}

@media screen and (min-width: 1024px) {
  .games-category {
    max-width: 80%;
  }

  .games-category-games {
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  }
}

@media screen and (min-width: 1440px) {
  .games-category {
    max-width: 60%;
  }
}

.not-found {
  margin-top: 3rem;
  min-height: 86vh;
  text-align: center;
  color: white;
}
.order {
  margin-bottom: 3rem;
}

.order-id-time {
  display: flex;
  background-color: var(--cartItemBackgroundColor);
  grid-gap: 3rem;
  gap: 3rem;
  padding: .25rem;
  border-bottom: 1px solid var(--navBlue);
}

@media screen and (min-width: 768px) {
  .order-id-time {
    padding: .25rem 1rem;
  }
}
