.searchbar {
  display: flex;
  gap: .25rem;
}

.searchbar input {
  background-color: white;
  width: 100%;
  border-radius: 5px;
  padding: 0.25rem;
}

.searchbar input::placeholder {
  color: var(--navDark);
}

.searchbar button {
  background-color: var(--navDark);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  cursor: pointer;
}

.searchbar button img {
  width: 1.5rem;
}