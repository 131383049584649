.game-card {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--backgroundCard);
  min-width: 0;
}

.game-card img {
  width: 100%;
}

.game-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0.25rem;
}

.game-info span {
  display: block;
  font-size: 0.75rem;
  color: white;
}

.game-original-price {
  text-decoration: line-through;
  text-align: right;
}

.game-info-price {
  background-color: var(--backgroundColor);
  padding: 0.25rem;
}

.game-info-discount {
  background-color: green;
}

.game-price-with-discount {
  display: flex;
  align-items: center;
}

.game-name-limit {
  min-width: 0;
  flex-basis: 50%;
}

.game-info-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

