.order {
  margin-bottom: 3rem;
}

.order-id-time {
  display: flex;
  background-color: var(--cartItemBackgroundColor);
  gap: 3rem;
  padding: .25rem;
  border-bottom: 1px solid var(--navBlue);
}

@media screen and (min-width: 768px) {
  .order-id-time {
    padding: .25rem 1rem;
  }
}