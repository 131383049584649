.game-detail {
  position: relative;
  color: white;
}

.game-detail video {
  max-width: 100%;
}

.game-detail-info {
  max-width: 96%;
  margin: 0 auto ;
}

.top-title {
  margin: 1rem 0;
}

.game-detail-info img {
  width: 100%;
}

.game-detail-imgs {
  display: flex;
  flex-direction: column;
  background-color: var(--navDark);
  height: 100%;
  justify-content: space-between;
}

.game-detail-small-img {
  display: flex;
  margin-top: 1rem;
  gap: .5rem;
}

.game-detail-small-img div{
  flex: 1 1 0;
  cursor: pointer;
}

.game-detail-video {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.game-detail-video img {
  position: absolute;
  width: 30%;
}

.game-detail-tags-price {
  margin-top: 2rem;
  background-color: var(--navDark);
}

.game-detail-tags-price h2{
  display: none;
}

.game-detail-tag-color {
  color: var(--textColor);
}

.game-detail-tags-price p {
  margin-top: 1rem;
}

.game-detail-short-description {
  display: none;
}

.tags-wrap {
  display: flex;
  flex-wrap: wrap;
}

.tags-wrap a{
  padding: .25rem .5rem;
  background-color: var(--backgroundCard);
  border-radius: 4px;
  margin: 0 .5rem .5rem 0;
  font-size: .75rem;
}


.game-detail-offer-ends {
  color: var(--backgroundCard);
  font-size: .8rem;
}

.game-detail-price-bg-color {
  background-color: #2c3943;
  border-radius: 5px;
  padding: 0 .5rem .5rem .5rem;
}

.align-price-right {
  display: flex;
  justify-content: flex-end;
}

.game-detail-price {
  background-color: var(--navDark);
  display: inline-flex;
  align-items: center;
  padding: .25rem;
  margin-top: 1rem;
  text-align: right;
}

.game-price-nodiscount {
  padding: 0 .25rem;
}

.game-detail-discount-percent {
  background-color: green;
  padding: .5rem;
}

.game-detail-discount {
  margin: 0 .5rem;
  display: inline-block;
}

.game-detail-orignal-price {
  color: var(--textColor);
  text-decoration: line-through;
  font-size: .75rem;
}

.game-detail-btn {
  background-color: green;
  color: white;
  padding: .5rem;
  border: 1px solid green;
  border-radius: 5px;
  cursor: pointer;
}


.game-description-requirments {
  max-width: 96%;
  margin: 3rem auto 3rem;
  background-color: var(--navDark);
  color: var(--textColor);
}

.game-detail-section-h3 {
  background-color: var(--backgroundColor);
  color: white;
  padding-bottom: 1rem;
}

.game-description {
  white-space: pre-line;
  padding: 0.5rem;
}

.text-limit {
  max-height: 45vh;
  overflow: hidden;
}

.read-more {
  color: var(--backgroundCard);
  text-align: center;
}

.requirments {
  padding: 0.5rem;
}

.requirments p{
  margin-bottom: .5rem;
}

.requirments p:nth-child(7){
  margin-bottom: 3rem;
}

@media screen and (min-width: 1024px) {
  .game-detail {
    margin-top: 3rem;
  }

  .game-detail-info {
    background-color: var(--navDark);
    max-width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: flex-end;
  }

  .top-title {
    display: none;
  }

  .game-detail-tags-price {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .game-detail-tags-price h3 {
    padding-left: .5rem;
  }

  .game-detail-tags {
    padding-left: .5rem;
  }

  .game-description-requirments {
    max-width: 80%;
  }

  .requirments {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .requirments p:nth-child(7){
    margin-bottom: .5rem;
  }

  .game-detail-price-bg-color {
    border-radius: 0;
  }
}

@media screen and (min-width: 1440px) {
  .game-detail-info {
    max-width: 60%;
  }

  .game-description-requirments {
    max-width: 60%;
  }

  .game-detail-tags-price h3 {
    display: none;
  }

  .game-detail-tags-price h2 {
    display: block;
    padding-left: .5rem;
  }
}